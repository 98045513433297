import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const GameChanging = () => {
  return (
    <div className="bg-quaternary border-black border-b-2">
      <div className="container flex flex-col justify-center h-full w-full items-center text-center gap-5 md:gap-10 xl:gap-16 py-5 md:py-10 xl:py-20 ">
        <h1 className="text-2xl md:text-4xl xl:text-5xl font-semibold">Game-changing how?</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 text-left font-mono text-base md:text-xl xl:text-2xl gap-5 max-w-4xl">
          <div className="flex flex-col gap-5 ">
            <p>The context has changed.</p>
            <p>
              Talent can be hired from anywhere in the world, and developers now have more options than ever before.
              However, blurring geographical lines introduces a new challenge: in a more competitive market, how can I
              present myself in an effective and bias-free way?.
            </p>
            <p>
              Slashscore adds value by functioning as a living proof: your profile is constantly updated based on your
              activity and your contributions, providing the right metrics to measure your impact and skills
            </p>
          </div>

          <div className="flex justify-center h-48 md:h-full xl:h-full">
            <StaticImage src="../images/game-changing-right.png" alt="Woman" quality={100} objectFit="contain" />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 text-left font-mono text-base md:text-xl xl:text-2xl gap-5 max-w-4xl">
          <StaticImage
            src="../images/game-changing-left.png"
            alt="People"
            quality={100}
            className="order-last md:order-first xl:order-first"
          />

          <div className="flex flex-col gap-5 ">
            <p>
              The future of work presents itself as market-driven by seasoned developers (leaders, managers, and
              entrepreneurs) looking for passionate people to work with.
            </p>
            <p>Use Slashscore to extend your network and get a sense of the skills of others.</p>

            <Link to="/" className=" py-4 bg-black text-white text-sm md:text-base xl:text-lg w-1/2 text-center">
              Launch Slashscore
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameChanging;
