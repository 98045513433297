import React from "react";
import { Link } from "gatsby";

const SingleSection = () => {
  return (
    <div className="container flex flex-col h-fit w-full items-center gap-5 md:gap-10 xl:gap-12 py-5 md:py-10 xl:py-20 ">
      <h1 className="text-2xl md:text-4xl xl:text-5xl font-semibold text-center">
        As a developer<span className="text-secondary">,</span>
        <br></br> where do you stand?
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-3 gap-5 md:gap-10 xl:gap-10 max-w-4xl">
        <div className="flex flex-col gap-2 md:gap-5 xl:gap-5 w-fit md:w-72 xl:w-80">
          <h2 className="text-base md:text-xl xl:text-2xl font-medium">Launch Point?</h2>
          <p className="font-mono w-fit md:w-5/6 xl:w-5/6 opacity-70 text-sm md:text-lg xl:text-xl">
            You need recognition and support for your fresh vision and courage.
          </p>
        </div>
        <div className="flex flex-col gap-2 md:gap-5 xl:gap-5 w-fit md:w-64 xl:w-72">
          <h2 className="text-base md:text-xl xl:text-2xl font-medium">Sweet Spot?</h2>
          <p className="font-mono w-fit md:w-5/6 xl:w-5/6 opacity-70 text-sm md:text-lg xl:text-xl">
            You need to prioritize what is effective and work at your own pace.
          </p>
        </div>
        <div className="flex flex-col gap-2 md:gap-5 xl:gap-5 w-fit md:w-64 xl:w-72">
          <h2 className="text-base md:text-xl xl:text-2xl font-medium">Mastery?</h2>
          <p className="font-mono w-fit md:w-5/6 xl:w-5/6 opacity-70 text-sm md:text-lg xl:text-xl">
            You need new challenges and learning experiences.
          </p>
        </div>
      </div>

      <div className="relative flex items-center justify-center text-center">
        <div className="relative hidden md:inline-block xl:inline-block">
          <img src="/create-profile-box.png" alt="Box" width={940} />
          <img
            src="/box-dots.png"
            alt="Dots"
            width={190}
            className="-right-16 top-1/2"
            style={{ position: "absolute" }}
          />
        </div>

        <div className="md:absolute xl:absolute flex flex-col gap-2 md:gap-5 xl:gap-5 font-mono text-base md:text-lg xl:text-xl w-full md:w-3/5 xl:w-2/3 ">
          <p className="opacity-80 leading-7">
            Slashscore enables you to do more, become better, and showcase your accomplishments.
          </p>
          <p className="font-semibold leading-7">
            Create your profile. See how you score.<br></br>
            <Link to="#join-us" className="hover:underline">
              Join our community.
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SingleSection;
