export const testimonials = [
  {
    authorText:
      "I recently found Slashscore and it's been a game-changer for my software development journey. The ability to compete with fellow developers from around the world and track my progress has been a huge boost to my motivation. And the seamless integrations keeps my expert profile shining bright, showcasing my skills and achievements to the world. If you're a developer looking to level up your career with some fun, Slashscore is definitely the way to go.",
    authorName: "Razvan Topliceanu, Frontend Developer • Freelancer • Remote • Contractor",
    authorUsername: "lettucetraceable5446",
    authorImageUrl: `/testimonials/razvan-topliceanu.jpeg`,
  },

  {
    authorText:
      "Slashscore has changed the way I work as a developer. Its strong integrations and global developer network have given me a lot of chances to learn and grow. Slashscore has always been a great resource for me, whether I'm working on a new project or trying to improve my skills. I would highly recommend it to any developer who wants to improve their skills and meet other developers from all over the world.",
    authorName: "Florin Holhos, Full-Stack Developer | React & Node.js",
    authorUsername: "florinholhos927407",
    authorImageUrl: "/testimonials/florin-holhos.jpeg",
  },

  {
    authorText:"If you are a software developer interested in the market, I strongly suggest giving Slashscore a try. I had the chance to use the project early on, and I have been quite amazed by how it has developed and the potential it holds. Slashscore is, in my opinion, a fantastic platform with a promising future, and I can't wait to watch how users continue to embrace it.",
    authorName: "Alexandru Dornescu, Software Developer | Python",
    authorUsername: "alexandrudornescu19951895",
    authorImageUrl: "/testimonials/alexandru-dornescu.jpeg",
  },
];
