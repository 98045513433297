import React, { useCallback, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { emailTypes } from "../helpers/constants";
import { upperCaseObjectKeys } from "../helpers/functions";
import ThankYouModal from "./common/ThankYouModal";

const axios = require("axios");

const initialFormInputs = {
  firstname: "",
  email: "",
  fulldesc: "",
  type: emailTypes.feedback,
};

const GetInTouchSection = () => {
  const [formInputs, setFormInputs] = useState(initialFormInputs);
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState("");

  const handleChange = (e) => {
    setFormInputs({ ...formInputs, [e.currentTarget.name]: e.target.value });
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { email } = formInputs;

      const listFields = upperCaseObjectKeys(formInputs);
      delete listFields.EMAIL;

      // Send email through Mailchimp
      const result = await addToMailchimp(email, listFields);

      //check errors
      if (result.result === "error") {
        if (result.msg.includes("is already subscribed")) setShowModal(true);
        else setErrors("Something went wrong. Please try again in about 5 minutes.");
      } else if (result.result === "success") setShowModal(true);

      await axios({
        method: "POST",
        url: process.env.SLACK_WEBHOOK_URL,
        data: {
          text: formInputs.fulldesc,
          username: formInputs.email,
        },
        headers: {
          Accept: "application/json, text/plain, */*, application/x-www-form-urlencoded",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .catch((err) => {
          console.log(err.response);
        });

      setFormInputs(initialFormInputs);
    },
    [formInputs]
  );

  return (
    <div className="bg-black text-white" id="get-involved">
      <div className="container relative flex flex-col justify-center h-full w-full items-center text-center gap-5 md:gap-16 xl:gap-16 pt-5 md:pt-10 xl:pt-20">
        <div className="absolute left-3 md:left-0 xl:left-0 top-3 md:top-10 xl:top-10">
          <StaticImage src="../images/loop.png" alt="Extra score" quality={100} className="w-20 md:w-full xl:w-full" />
        </div>
        <h1 className="text-2xl md:text-4xl xl:text-5xl font-semibold">Get in touch</h1>

        <div className="text-base md:text-lg xl:text-xl font-mono w-full md:w-3/4 xl:w-3/4 flex flex-col gap-5">
          <p>
            As a community, we honor and congratulate all digital product developers from around the world who pursue
            their aspirations and goals.
          </p>
          <p>
            This is a revolution for all the brilliant software engineers out there: let's make a name for ourselves and
            showcase our worth.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-10 text-left max-w-3xl">
          <div className="relative text-base md:text-lg xl:text-xl flex flex-col gap-5 justify-center">
            <h2 className="font-medium text-lg md:text-xl xl:text-2xl">Get involved</h2>
            <p className="font-mono">We value feedback.</p>
            <p className="font-mono w-full md:w-1/2 xl:w-1/2">We enjoy having conversations.</p>
            <p className="font-mono w-full md:w-3/4 xl:w-3/4 mb-0 md:mb-10 xl:mb-10">
              We&apos;d appreciate it if you could help us improve Slashscore.
            </p>

            <div className="hidden md:inline-block xl:inline-block">
              <StaticImage
                src="../images/send-arrow.png"
                alt="arrow"
                className="bottom-2 right-0 "
                quality={100}
                style={{ position: "absolute" }}
              />
            </div>
          </div>

          <form onSubmit={handleSubmit} className="flex flex-col gap-5 text-black">
            <input required type="text" placeholder="Your Name" name="firstname" className="p-4" onChange={handleChange} />
            <input
              required
              type="email"
              name="email"
              placeholder="Your Email Address"
              className="p-4"
              onChange={handleChange}
            />
            <textarea
              required
              placeholder="Your message"
              name="fulldesc"
              className="p-4 resize-none"
              rows={6}
              onChange={handleChange}
            />

            {errors ? <div className="text-red">{errors}</div> : null}

            <button type="submit" className="bg-primary p-4 text-black text-lg mb-5 md:mb-0 xl:mb-0">
              Send it!
            </button>
          </form>
        </div>
      </div>

      {showModal ? <ThankYouModal open={showModal} close={true} handleClose={() => setShowModal(false)} /> : null}

      <StaticImage src="../images/get-in-touch.png" alt="footer" quality={100} layout="fullWidth" />
    </div>
  );
};

export default GetInTouchSection;
