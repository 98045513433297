import React from "react";
import { Link } from "gatsby";

import { Carousel } from "react-responsive-carousel";

const BlogCarousel = (props) => {
  const { articles, title } = props;

  return (
    <div className="container flex flex-col h-fit w-full gap-8 py-5 md:py-10 xl:py-10 ">
      <h2 className="font-semibold text-2xl">{title}</h2>

      <div className="hidden md:block xl:block" id="blog">
        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          autoPlay
          infiniteLoop
          centerMode={true}
          centerSlidePercentage={45}
          swipeable
          swipeScrollTolerance={5}
          thumbWidth={100}
          emulateTouch={true}
          transitionTime={500}
          interval={5000}
        >
          {articles.map((edge, index) => {
            const articleContent = edge.node.frontmatter;
            return (
              <div key={index} className="text-left mr-0 md:mr-24 xl:mr-24">
                <p className="opacity-60 text-lg">{articleContent.category}</p>
                <Link to={articleContent.path} className="hover:underline">
                  <h1 className="font-semibold mb-5 ">{articleContent.title}</h1>
                  <p className="font-mono">{articleContent.shortDescription}</p>
                </Link>
              </div>
            );
          })}
        </Carousel>
      </div>

      <div className="block md:hidden xl:hidden" id="blog">
        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          autoPlay
          infiniteLoop
          swipeable
          swipeScrollTolerance={90}
          thumbWidth={100}
          emulateTouch={true}
          transitionTime={500}
          interval={5000}
        >
          {articles.map((edge, index) => {
            const articleContent = edge.node.frontmatter;
            return (
              <div key={index} className="text-left mr-0 md:mr-24 xl:mr-24">
                <p className="opacity-60 text-lg">{articleContent.category}</p>
                <Link to={articleContent.path} className="hover:underline">
                  <h1 className="font-semibold mb-5 ">{articleContent.title}</h1>
                  <p className="font-mono">{articleContent.shortDescription}</p>
                </Link>
              </div>
            );
          })}
        </Carousel>
      </div>
      <Link to="/updates" className=" p-4 border-black border-2 text-lg w-1/2 md:w-1/6 xl:w-1/6 text-center">
        Visit Blog
      </Link>
    </div>
  );
};

export default BlogCarousel;
