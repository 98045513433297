import React from "react";
import { Carousel } from "react-responsive-carousel";
import { StaticImage } from "gatsby-plugin-image";

import { testimonials } from "../helpers/testimonials";

const Testimonials = () => {
  return (
    <div className="bg-primary border-black border-b-2">
      <div className="container flex flex-col justify-center h-full w-full items-center text-center gap-5 md:gap-16 xl:gap-16 py-5 md:py-10 xl:py-20 ">
        <h1 className="text-2xl md:text-4xl xl:text-5xl font-semibold">
          Want to see how you rank
          <br></br> in Slashscore?
        </h1>

        <p className="text-base md:text-lg xl:text-xl font-mono">
          How would you use integrations to your advantage?<br></br>Check out some of the existing profiles.
        </p>

        <div className="relative max-w-xs md:max-w-4xl xl:max-w-6xl h-full" id="testimonials">
          <Carousel
            autoPlay
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            infiniteLoop
            swipeable
            swipeScrollTolerance={90}
            thumbWidth={100}
            emulateTouch={true}
            transitionTime={500}
            interval={5000}
          >
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className="text-left font-mono text-base md:text-xl xl:text-2xl flex flex-col xl:grid md:grid md:grid-cols-2 xl:grid-cols-2 gap-5 md:gap-10 xl:gap-10"
              >
                <div className="mb-0 md:mb-10 xl:mb-10">
                  <p>{testimonial.authorText}</p>
                  <p className="font-medium text-base mt-5 font-sans">{testimonial.authorName}</p>
                </div>

                <div className="flex flex-col gap-3 mb-8 md:mb-0 xl:mb-0">
                  <img
                    src={testimonial.authorImageUrl}
                    alt={testimonial.authorName}
                    className="mask-gem object-cover h-[250px] xl:h-[430px]"
                  />

                  <div className="w-4/5 md:w-3/5 xl:w-[45%]">
                    <a
                      href={`https://app.slashscore.com/${testimonial.authorUsername}`}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="relative flex justify-center items-center"
                    >
                      <img src="/slashscore-account.svg" alt="Slashscore account" />
                      <span className="absolute flex text-xs md:text-xs xl:text-xs font-semibold ml-14 w-3/5 truncate">
                        @{testimonial.authorUsername}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>

          <StaticImage
            src="../images/cloud.png"
            alt="Cloud"
            className="right-0 bottom-full w-32 md:w-52 xl:w-52"
            style={{ position: "absolute" }}
            objectFit="contain"
          />
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
