import React from "react";

const RealTimeProfiling = () => {
  return (
    <div className="w-full bg-[#181A39] text-white">
      <div className="container flex flex-col md:grid xl:grid md:grid-cols-2 xl:grid-cols-2 h-full">
        <div className="flex h-full justify-center py-5 md:py-20 xl:py-20 ">
          <div className="flex flex-col gap-3 md:gap-8 xl:gap-8 justify-center">
            <h1 className="text-4xl md:text-5xl font-semibold w-full">
              We build & scale personal brands of software engineers who inspire & make an impact.
            </h1>
            <p className="font-mono opacity-70 text-sm md:text-base xl:text-lg max-w-2xl">
              More accurate than a portfolio, more exciting than a resume, and more noticeable than your favorite T-shirt.
            </p>

            <div className="flex flex-col gap-2 w-3/5 md:w-4/5 xl:w-1/3 max-w-xs text-center">
              <a
                href="https://app.slashscore.com"
                target="_blank"
                rel="noreferrer"
                className=" py-4 bg-primary text-black text-sm md:text-base xl:text-lg"
              >
                Get Started
              </a>
              <p className="font-mono text-xs md:text-xs xl:text-sm text-left md:text-center xl:text-center ">
                <span className="font-semibold">v2.1 • 1500+ </span>members
              </p>
            </div>
          </div>
        </div>

        <div className="flex justify-center h-full items-center py-5">
          <img src="/real-time-profiling-new.svg" alt="Profiling" className="ml-5" />
        </div>
      </div>
    </div>
  );
};

export default RealTimeProfiling;
