import { StaticImage } from "gatsby-plugin-image";
import React, { useCallback, useState } from "react";

const HowItWorks = () => {
  const [navOption, setNavOption] = useState("profile");

  const navOptions = [
    {
      title: "My Profile",
      criteria: "profile",
      description:
        "Everything from your scores, activity, professional information, projects and work experience, in one place.",
    },
    {
      title: "Activity Dashboard",
      criteria: "dashboard",
      description: "Manage all of your integrations and check out your scores’ breakdown.",
    },
    {
      title: "Home Feed",
      criteria: "feed",
      description: "Feed with the social media activity of the developers you follow. Filter by new or popular",
    },
  ];

  const getImage = useCallback((option) => {
    switch (option) {
      case "profile":
        return <StaticImage src="../images/profile.png" alt="My profile" quality={100} />;
      case "dashboard":
        return <StaticImage src="../images/activity-dashboard.png" alt="Dashboard" quality={100} />;
      case "feed":
        return <StaticImage src="../images/feed.png" alt="Home feed" quality={100} />;
      default:
        return;
    }
  }, []);

  return (
    <div className="border-black border-b-2 w-full" id="how-it-works">
      <div className="container flex flex-col justify-center h-full w-full items-center text-center gap-5 md:gap-10 xl:gap-10 py-5 md:py-10 xl:py-20 ">
        <h1 className="text-2xl md:text-4xl xl:text-5xl font-semibold">How it works</h1>

        <div className="font-mono flex flex-col gap-3 md:gap-10 xl:gap-10 text-base md:text-lg xl:text-xl w-full md:w-3/4 xl:w-3/4">
          <p>
            Through integrations, Slashscore allows you to compete with other software developers from around the world
            as well as yourself, and to share your experiences and impact on the software industry. Plus, you'll always
            have an up-to-date expert profile that shows how valuable and important your work is and how much you care
            about sharing knowledge.
          </p>
          <p className="font-semibold">
            Use it to become a better version of yourself!<br></br>
            You will also have access to our amazing community.
          </p>
        </div>

        <div className="flex flex-col md:flex-row xl:flex-row justify-between items-center h-full md:h-96 xl:h-96 gap-3">
          <div className="flex flex-row md:flex-col xl:flex-col text-sm md:text-xl xl:text-2xl items-start gap-5 md:gap-3 xl:gap-3">
            {navOptions.map((filter, index) => {
              const selected = navOption === filter.criteria;
              return (
                <div className="flex items-center gap-6" key={index}>
                  <button key={filter.criteria} onClick={() => setNavOption(filter.criteria)}>
                    <p className={`${selected ? "font-medium " : " font-medium opacity-50"} text-start`}>
                      {filter.title}
                    </p>
                  </button>

                  {selected ? (
                    <div className="hidden md:inline-block xl:inline-block">
                      <svg width="80" height="16" viewBox="0 0 52 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M51.2071 8.70711C51.5976 8.31658 51.5976 7.68342 51.2071 7.29289L44.8431 0.928932C44.4526 0.538408 43.8195 0.538408 43.4289 0.928932C43.0384 1.31946 43.0384 1.95262 43.4289 2.34315L49.0858 8L43.4289 13.6569C43.0384 14.0474 43.0384 14.6805 43.4289 15.0711C43.8195 15.4616 44.4526 15.4616 44.8431 15.0711L51.2071 8.70711ZM0 9H50.5V7H0V9Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>

          <div className="justify-center">{getImage(navOption)}</div>

          <p className="font-mono w-full md:w-1/4 xl:w-1/4 opacity-70 text-base md:text-lg xl:text-xl text-left ">
            {navOptions.find((o) => o.criteria === navOption).description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
