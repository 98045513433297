import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "gatsby";

import Cookies from "universal-cookie";

const isDevelopment = process.env.NODE_ENV === "development";

const CookieOptions = (props) => {
  const { title, description, checked, disabled, setCookie } = props;
  const [check, setCheck] = useState(checked);

  useEffect(() => {
    setCookie(check);
  }, [check, setCookie]);

  return (
    <div className="bg-gray/20 text-white p-3 md:p-5 xl:p-5 rounded-md text-sm md:text-base xl:text-base">
      <div className="flex ">
        <p className="w-full">{title}</p>

        <div
          className={`flex items-start md:items-center xl:items-center justify-end pr-0.5 ${
            disabled ? "opacity-50" : ""
          }`}
        >
          <button
            className="relative"
            onClick={() => {
              setCheck(!check);
            }}
            disabled={disabled}
          >
            <div className="block bg-black/75 w-10 h-5 rounded-full"></div>
            <div
              className={`${
                check ? "bg-primary translate-x-full" : "bg-white"
              } absolute left-1 top-0.5 w-4 h-4 rounded-full transition`}
            ></div>
          </button>
        </div>
      </div>
      <p className="text-xs opacity-75 w-full md:w-4/5 xl:w-4/5">{description}</p>
    </div>
  );
};

const CookieBanner = () => {
  const cookies = useMemo(() => {
    return new Cookies();
  }, []);
  const consentCookie = cookies.get(process.env.COOKIE_CONSENT);
  const [cookiesAccepted, setCookiesAccepted] = useState(!!consentCookie);

  const [analytics, setAnalytics] = useState(false);
  const [functional, setFunctional] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);

  const handleSubmit = useCallback(() => {
    cookies.set(
      process.env.COOKIE_CONSENT,
      { analytics, functional, marketing },
      {
        secure: !isDevelopment,
        httpOnly: false,
        path: "/",
        sameSite: "lax",
        expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7 * 365),
      }
    );
    setCookiesAccepted(true);
  }, [setCookiesAccepted, analytics, functional, marketing, cookies]);

  if (typeof window === "undefined" || cookiesAccepted) return;

  return (
    <div className="bg-[#4f4d4d] text-white w-full sticky bottom-0 py-6 z-30">
      <div className="container">
        <div className="flex flex-col md:flex-row xl:flex-row gap-5 justify-between text-sm md:text-base xl:text-base items-end">
          <p className="">
            Slashscore uses cookies to offer you a better experience. See&nbsp;
            <Link
              to={`${process.env.HOST}/cookies-policy`}
              target="_blank"
              rel="noreferrer noopener"
              className="underline"
            >
              Cookie Notice
            </Link>
            &nbsp;for details.
          </p>

          {openOptions ? (
            <button
              onClick={handleSubmit}
              className="bg-primary text-black py-1 px-2 rounded-md hover:scale-105 order-first md:order-last xl:order-last"
            >
              Done
            </button>
          ) : (
            <div className="flex gap-5 md:gap-10 xl:gap-10">
              <button
                className="bg-primary text-black py-1 px-2 rounded-md hover:scale-105"
                onClick={() => setOpenOptions(true)}
              >
                More options
              </button>

              <button onClick={handleSubmit} className="hover:scale-105 py-1 px-2">
                Accept all
              </button>
            </div>
          )}
        </div>
        {openOptions ? (
          <div className="grid grid-cols-2 md:grid-cols-2 xl:grid-cols-4 gap-3 mt-5">
            <CookieOptions
              title="Strictly necessary"
              description="Essential for the site to function. Always on."
              checked={true}
              disabled={true}
              setCookie={() => {}}
            />

            <CookieOptions
              title="Functional"
              description="Used to remember preference selections and provide enhanced features."
              checked={functional}
              setCookie={setFunctional}
            />

            <CookieOptions
              title="Analytics"
              description="Used to measure usage and improve your experience."
              checked={analytics}
              setCookie={setAnalytics}
            />

            <CookieOptions
              title="Marketing"
              description="Used for targeted advertising."
              checked={marketing}
              setCookie={setMarketing}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CookieBanner;
