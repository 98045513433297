import React from "react";
import { graphql } from "gatsby";

import Header from "../components/common/Header";
import RealTimeProfiling from "../components/RealTimeProfilingSection";
import SingleSection from "../components/SingleSection";
import HowItWorks from "../components/HowItWorksSection";
import TopIntegrations from "../components/TopIntegrationsSection";
import GameChanging from "../components/GameChangingSection";
import AboutUs from "../components/AboutUsSection";
import JoinCommunity from "../components/JoinCommunitySection";
import GetInTouchSection from "../components/GetInTouchSection";
import Footer from "../components/common/Footer";
import BlogCarousel from "../components/blog/BlogCarousel";
import DoubleSection from "../components/common/DoubleSection";
import SeoDeveloper from "../components/common/SeoDeveloper";
import CookieBanner from "../components/CookieBanner";
import Testimonials from "../components/TestimonialsSection";

const IndexPage = ({ data }) => {
  return (
    <>
      <SeoDeveloper />
      <Header color="bg-primary text-black" />
      <main>
        <RealTimeProfiling />

        <SingleSection />

        <div className="text-center text-white bg-black border-b-2 border-black py-3 md:py-5 xl:py-8 font-semibold" id="why-slashscore">
          <h1 className="text-2xl md:text-4xl xl:text-5xl">Why Slashscore?</h1>
        </div>

        <DoubleSection
          title="efficient"
          description="In an era facing an explosion in behavioral sciences research, Slashscore is a coherent analytics tool focused on promoting and enhancing your developer's journey with reflexive assessment."
          background="bg-split-primary"
          divider="divide-y-0 md:divide-x-2 xl:divide-x-2 divide-black "
        />

        <DoubleSection
          title="insightful"
          description="Curating data from integrations promotes and enhances your role in the software world. Slashscore provides access to up-to-date information about your activities and meaningful contributions."
          background="bg-split-secondary"
          order="border-black border-b-0 md:border-l-2 xl:border-l-2 order-first md:order-last xl:order-last"
        />

        <DoubleSection
          title="inspiring"
          description="Stay on track and stay curious: Slashscore is the newest hotspot for curated content created by software ambassadors, mentors, and members of the community."
          background="bg-split-tertiary"
          divider="divide-y-0 md:divide-x-2 xl:divide-x-2 divide-black "
        />

        <HowItWorks />
        <TopIntegrations />
        <Testimonials />
        <GameChanging />
        <AboutUs />
        <JoinCommunity />
        <BlogCarousel articles={data.allMarkdownRemark.edges} title="Additional resources" />
        <GetInTouchSection />
      </main>
      <Footer />
      <CookieBanner />
    </>
  );
};

export default IndexPage;

export const articlesQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      limit: 100
      filter: { frontmatter: { type: { eq: "article" } } }
    ) {
      edges {
        node {
          frontmatter {
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1000, maxHeight: 500, quality: 80) {
                  ...GatsbyImageSharpFluid_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
            author
            shortDescription
            title
            category
            path
          }
        }
      }
    }
  }
`;
