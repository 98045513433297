import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const AboutUs = () => {
  const team = [
    {
      name: "Corina",
      position: "Co-founder",
      image: <StaticImage src="../images/team/corina.png" alt="Corina" quality={100} />,
      background: "bg-primary",
    },
    {
      name: "Daniel",
      position: "Co-founder",
      image: <StaticImage src="../images/team/daniel.png" alt="Daniel" quality={100} />,
      background: "bg-quaternary",
    },
    {
      name: "Radu",
      position: "Lead Designer",
      image: <StaticImage src="../images/team/radu.png" alt="Radu" quality={100} />,
      background: "bg-secondary",
    },
    {
      name: "Alexandru",
      position: "Full-Stack Developer",
      image: <StaticImage src="../images/team/alexandru.png" alt="Alexandru" quality={100} height={250}  />,
      background: "bg-secondary",
    },
    {
      name: "Casiana",
      position: "Full-Stack Developer",
      image: <StaticImage src="../images/team/casiana.png" alt="Casiana" quality={100} height={250} />,
      background: "bg-tertiary",
    },
    {
      name: "Radu",
      position: "Mentor",
      image: <StaticImage src="../images/team/radu-mentor.png" alt="Radu-mentor" quality={100} height={250} />,
      background: "bg-quaternary",
    },
  ];

  return (
    <div className="border-black border-b-2">
      <div className="container flex flex-col h-fit w-full items-center gap-5 md:gap-10 xl:gap-12 py-5 md:py-10 xl:py-20 text-center ">
        <h1 className="text-2xl md:text-4xl xl:text-5xl font-semibold text-center">About us</h1>

        <div className="text-base md:text-lg xl:text-xl font-mono w-full md:w-3/4 xl:w-3/4 flex flex-col gap-5">
          <p>
            With clients headquartered in both the United States and Europe, Wingravity develops and markets Slashscore.
          </p>

          <p>
            Since 2017, we've been considering the possibility of creating our very own product from the ground up. When
            we first started using it, we referred to it as "the resume of the future for anyone who codes."
          </p>
          <p>
            In 2020, we opted to double down on expansion rather than draw back, and a consequence of the
            pandemic&apos;s disruption was that we finally got to concentrate our efforts and resources on Slashscore.
          </p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-2 xl:grid-cols-3 gap-8 w-full md:w-3/4 xl:w-3/4 ">
          {team.map((member, index) => (
            <div className="text-left text-base md:text-xl xl:text-2xl" key={index}>
              <div className={`${member.background} border-black border-2 mb-5 flex justify-center h-36 md:h-64 xl:h-64`}>
                {member.image}
              </div>

              <p className="font-medium">{member.name}</p>
              <p>{member.position}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
