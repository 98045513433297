import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const TopIntegrations = () => {
  return (
    <div className="border-black border-b-2 w-full" id="integrations">
      <div className="container relative flex flex-col justify-center h-full w-full items-center text-center gap-5 md:gap-10 xl:gap-16 py-5 md:py-10 xl:py-20 ">
        <div className="relative w-5/6">
          <h1 className="text-2xl md:text-4xl xl:text-5xl font-semibold z-10">
            Top integrations <br></br>for calculating scores
          </h1>

          <div className="flex gap-1 mt-5 md:mt-5 xl:mt-0 h-fit justify-center">
            <div className="w-10 relative md:relative xl:absolute md:w-16 xl:w-16 bottom-0 md:bottom-0 xl:bottom-14 left-0 md:left-0 xl:left-32">
              <StaticImage src="../images/providers/github.png" alt="Github" quality={100} />
            </div>
            <div className="w-10 relative md:relative xl:absolute md:w-16 xl:w-16 bottom-0 md:bottom-0 xl:bottom-12 left-0 md:left-0 xl:-left-8">
              <StaticImage src="../images/providers/wakatime.png" alt="Wakatime" quality={100} />
            </div>
            <div className="w-10 relative md:relative xl:absolute md:w-16 xl:w-16 bottom-0 md:bottom-0 xl:-bottom-8 left-0 md:left-0 xl:-left-32">
              <StaticImage src="../images/providers/bitbucket.png" alt="Bitbucket" quality={100} />
            </div>
            <div className="w-10 relative md:relative xl:absolute md:w-16 xl:w-16 bottom-0 md:bottom-0 xl:-bottom-14 left-0 md:left-0 xl:left-8">
              <StaticImage src="../images/providers/gitlab.png" alt="Gitlab" quality={100} />
            </div>
            <div className="w-10 relative md:relative xl:absolute md:w-16 xl:w-16 bottom-0 md:bottom-0 xl:bottom-14 right-0 md:right-0 xl:-right-32">
              <StaticImage src="../images/providers/stackoverflow.png" alt="Stackoverflow" quality={100} />
            </div>
            <div className="w-10 relative md:relative xl:absolute md:w-16 xl:w-16 bottom-0 md:bottom-0 xl:bottom-8 right-0 md:right-0 xl:right-5">
              <StaticImage src="../images/providers/twitter.png" alt="Twitter" quality={100} />
            </div>
            <div className="w-10 relative md:relative xl:absolute md:w-16 xl:w-16 bottom-0 md:bottom-0 xl:-bottom-16 right-0 md:right-0 xl:right-20">
              <StaticImage src="../images/providers/meetup.png" alt="Meetup" quality={100} />
            </div>
            <div className="w-10 relative md:relative xl:absolute md:w-16 xl:w-16 bottom-0 md:bottom-0 xl:-bottom-12 right-0 md:right-0 xl:-right-14">
              <StaticImage src="../images/providers/medium.png" alt="Medium" quality={100} />
            </div>
          </div>
        </div>

        <p className="text-base md:text-lg xl:text-xl font-mono w-full md:w-3/4 xl:w-3/4">
          Metrics such as Efficiency, Impact, Frequency, Influence, Quality, Popularity, and Reputation, which are used
          in trendlines to help you track and understand your evolution, are calculated using non-disputable and
          real-time data from 10+ integrations.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-5 md:gap-10 xl:gap-10 text-left max-w-4xl">
          <div className="bg-primary rounded-md border-black border-2 px-5 md:px-9 xl:px-9 py-5 md:py-12 xl:py-12 flex flex-col gap-3 md:gap-8 xl:gap-8">
            <div className="flex gap-5 items-center">
              <StaticImage src="../images/professional-score.png" alt="Professional score" width={85} quality={100} />
              <h2 className="text-lg md:text-xl xl:text-2xl w-full md:w-min xl:w-min font-medium	">
                Professional Score
              </h2>
            </div>
            <p className="font-bold font-mono opacity-70 text-base md:text-lg xl:text-xl">
              Public Coding Skills and Expertise
            </p>
            <p className="opacity-70 font-mono text-base md:text-lg xl:text-xl">
              Use your Github, Gitlab, Bitbucket and Wakatime accounts to exhibit your core programming ability and your
              knowledge of fundamental concepts.
            </p>
          </div>

          <div className="bg-tertiary rounded-md border-black border-2 px-5 md:px-9 xl:px-9 py-5 md:py-12 xl:py-12 flex flex-col gap-3 md:gap-8 xl:gap-8">
            <div className="flex gap-5 items-center">
              <StaticImage src="../images/extra-score.png" alt="Extra score" width={85} quality={100} />
              <h2 className="text-lg md:text-xl xl:text-2xl w-full md:w-min xl:w-min font-medium	">Extra Score</h2>
            </div>
            <p className="font-bold font-mono opacity-70 text-base md:text-lg xl:text-xl">
              Technical Writing and Communication Skills
            </p>
            <p className="opacity-70 font-mono text-base md:text-lg xl:text-xl">
              In addition to demonstrating your experience, you can distinguish yourself from hundreds of other
              developers by integrating Medium or Dev.to.
            </p>
          </div>

          <div className="bg-quaternary rounded-md border-black border-2 px-5 md:px-9 xl:px-9 py-5 md:py-12 xl:py-12 flex flex-col gap-3 md:gap-8 xl:gap-8">
            <div className="flex gap-5 items-center">
              <StaticImage src="../images/community-score.png" alt="Community score" width={85} quality={100} />
              <h2 className="text-lg md:text-xl xl:text-2xl w-full md:w-min xl:w-min font-medium	">Community Score</h2>
            </div>
            <p className="font-bold font-mono opacity-70 text-base md:text-lg xl:text-xl">
              Analytical Skills and Community Involvement
            </p>
            <p className="opacity-70 font-mono text-base md:text-lg xl:text-xl">
              Integrate your Stackoverflow and Meetup accounts to highlight your contributions to the social well-being
              of the tech community.
            </p>
          </div>

          <div className="bg-secondary rounded-md border-black border-2 px-5 md:px-9 xl:px-9 py-5 md:py-12 xl:py-12 flex flex-col gap-3 md:gap-8 xl:gap-8">
            <div className="flex gap-5 items-center">
              <StaticImage src="../images/popularity-score.png" alt="Popularity score" width={85} quality={100} />
              <h2 className="text-lg md:text-xl xl:text-2xl w-full md:w-min xl:w-min font-medium	">Popularity Score</h2>
            </div>
            <p className="font-bold font-mono opacity-70 text-base md:text-lg xl:text-xl">
              Social Media Presence and Reputation
            </p>
            <p className="opacity-70 font-mono text-base md:text-lg xl:text-xl">
              A strong personal brand creates an impactful impression on people. Integrate Reddit, Twitter, and YouTube
              to voice your values to the tech public.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopIntegrations;
