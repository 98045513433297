import React, { useCallback } from "react";
import { StaticImage } from "gatsby-plugin-image";

const DoubleSection = ({ title, description, background, order, divider }) => {
  const getImage = useCallback((option) => {
    switch (option) {
      case "insightful":
        return <StaticImage src="../../images/everfresh.png" alt="everfresh" quality={100} width={480} />;
      case "inspiring":
        return <StaticImage src="../../images/inspiring.png" alt="inspiring" quality={100} width={480} />;
      case "efficient":
        return <StaticImage src="../../images/efficient.png" alt="efficient" quality={100} width={480} />;
      default:
        return;
    }
  }, []);

  return (
    <div className={`border-black border-b-2 w-full ${background}`}>
      <div className={`container flex flex-col md:grid xl:grid md:grid-cols-2 xl:grid-cols-2 h-full gap-5 md:gap-0 xl:gap-0 ${divider}`}>
        <div className={`flex justify-center mt-5 md:mt-0 xl:mt-0 py-0 md:py-10 xl:py-20 ${order ? order : ""}`}>
          <div className="w-11/12 md:w-3/4 xl:w-7/12 flex flex-col gap-3 md:gap-8 xl:gap-8 justify-center">
            <h1 className="text-2xl md:text-4xl xl:text-5xl font-semibold">
              {title}
              <span className="text-secondary">.</span>
            </h1>
            <p className="opacity-80 text-base md:text-xl xl:text-xl font-mono">{description}</p>
          </div>
        </div>

        <div className="flex justify-center py-5 md:py-20 xl:py-20">{getImage(title)}</div>
      </div>
    </div>
  );
};

export default DoubleSection;
